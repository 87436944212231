@import '../../../styles/index.scss';

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 500;
}

.is_error {
  z-index: 501;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  background: $white;
  border-radius: 8px;
  max-height: 100%;
  min-width: 350px;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);

  .modal__header {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
}
