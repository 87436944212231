.modal__wrapper {
  position: fixed;
  z-index: 501;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(19, 20, 21, 0.5);
  display: flex;

  .modal {
    height: 100%;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
  }
}
