@import '../../../styles/index';

.page-wrapper {
  width: 100%;
  display: flex;
  height: 100%;

  .section__wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    .banner {
      width: 100%;
      height: 10%;
      background-color: $white;

      .banner__logo {
        padding: 30px;
      }
    }
    &>section {
      width: 100%;
      height: 90%;
      padding: 25px;
    }
  }
}

.section_mbl {
  .section__wrapper {
    .banner {
      height: 7%;
      .banner__logo {
        padding: 10px;
      }
    }
    &>section {
      padding: 5px;
    }
  }
}
