@import "../../../../../styles/index";

.wrapper {
  display: grid;
  grid-template-columns: 0.2fr 4fr 0.5fr;
  align-items: center;
  padding: 5px 10px;
  background-color: $white;
  border-radius: 5px;
  margin-bottom: 15px;

  .icon_wrapper {
    position: relative;

    &:after {
      content: "|";
      font-size: 16px;
      position: absolute;
      top: 30%;
      right: 0;
      color: $gray1;
    }
  }
}
