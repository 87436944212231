@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// FONT
$font-family: "Inter",
"Lato",
"Roboto",
sans-serif;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$base-font-size: 13px;

$base-line-height: 1;
$base-letter-spacing: -0.41px;
