@import "../../../styles/index";

.images {
	width: auto;
	display: flex;

	.out-wrapper {
		display: flex;
		height: 100%;
		width: 100%;

		.main {
			background-color: #f1f2f3;
			flex-shrink: 0;
			overflow-y: auto;
			height: 100%;
			padding: 40px 20px;
			width: 380px;
			display: flex;
			flex-direction: column;

			.modal__title {
				margin: 40px 0;
			}

			.modal__header {
				align-self: flex-end;
				cursor: pointer;
			}

			.files-form {
				.files-form__list {
					display: grid;
					grid-template-columns: repeat(auto-fill, 90px);
					grid-gap: 15px;
					align-items: center;

					.files-form__list__item {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						cursor: pointer;
						width: 90px;
						height: 90px;
						border-radius: 10px;
						border: 1px solid $gray2;
						overflow: hidden;

						img {
							margin-bottom: 15px;
						}

						.files-form__list__item__filename {
							color: $gray4;
							font-size: 9px;
						}
					}

					.files-form__list__item_active {
						border: 2px solid $purple;

						.files-form__list__item__filename {
							color: $purple;
						}
					}
				}
			}
		}
	}
}

.images_mbl {
	width: 100%;

	.out-wrapper {
		.main {
			width: 100%;
		}
	}
}

.images_full-size {
	width: 100%;
}

