@import "../../../../../../styles/index.scss";

.entity_form {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	height: 100%;
	width: 100%;

	&::-webkit-scrollbar {
		display: none;
	}

	.form_title {
		padding-left: 20px;
	}

	.main_form {
		overflow-y: auto;
		height: 100%;
		margin-top: 40px;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.actions {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		margin-top: 20px;
	}
}

.entity_mbl {
	.main_form {
		overflow-y: visible;
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
	}
}

