@import '../../../../styles/index.scss';

.secondary__btn {
  background: transparent;
  border: none;
  border-radius: 10px;
  height: 50px;
  margin: 0;
  font-size: 18px;
  font-weight: $font-weight-bold;
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    margin-right: 5px;
    width: 22px;
    height: 22px;
  }
}
