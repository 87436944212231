@import 'styles/index';

.container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  h2 {
    margin-right: 15px;
  }
  .container_inner_buttons {
    display: flex;
    gap: 10px;
  }

}

.container_mbl {
  align-items: flex-start;
  flex-direction: column;

  .container_inner_buttons {
    flex-direction: column;
  }
}