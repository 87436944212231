@import '../../../../styles/index.scss';

.container__inner {
  display: flex;
  flex-direction: column;
  width: 100%;

  .select_wrapper {
    display: flex;
    justify-content: flex-end;

    margin-top: 20px;

    .headers {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .text {
        color: $gray1;
      }
    }
  }
}
