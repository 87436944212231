@import '../../../../styles/index.scss';

.nav__item {
  .item__inner {
    cursor: pointer;
    padding: 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 45px;
    min-width: 45px;

    img {
      width: 25px;
      height: 25px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(36, 34, 32, 0.69);
    }

    .item__arrow {
      transform: rotate(0deg);
    }
  }

  .inner__complex {
    .item__complex__arrow {
      transform: rotate(90deg);
    }

    .arrow__rotate {
      transform: rotate(-90deg);
    }
  }

  .nav__sub-menu {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .nav__sub-wrapper {
      display: flex;
      width: 90%;
      box-shadow: 2px 0px 0px 0px lightgray inset;

      &:last-child {
        box-shadow: none;
      }

      .union {
        width: 10%;
        border-bottom: 2px lightgray solid;
        border-left: 2px lightgray solid;
        margin-bottom: 18px;
        border-radius: 0 0 0 13px;
      }

      .nav__sub-item {
        padding: 10px 15px 10px 25px;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: rgba(36, 34, 32, 0.69);
        }
      }
    }
  }
}

.nav-item__collapsed {
  .item__inner {
    justify-content: center;

    span {
      display: none;
    }

    .item__arrow {
      display: none
    }
  }
  .inner__complex {
    .item__complex__arrow {
      display: none;
    }
  }
  .nav__sub-menu {
    display: none;
  }
}

.nav-item__active {

  .item__inner {
    background-color: rgba(36, 34, 32, 0.07);
    border-radius: 12px;

    span {
      color: $black;
    }

    .item__arrow {
      path {
        color: $black;
      }
    }
  }

  .nav__sub-menu {
    .sub-item__active {
      background-color: rgba(36, 34, 32, 0.07);
      border-radius: 12px;

      span {
        color: $black;
      }

      .item__arrow {
        path {
          color: $black;
        }
      }
    }
  }
}
