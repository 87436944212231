@import "../../../../../styles/index";

.message {
  width: fit-content;
  padding: 15px 0;
  min-width: 300px;
  margin: 0 0 0 auto;

  .owner {
    font-weight: $font-weight-bolder;
    font-size: 13px;
  }

  .body {
    color: $black;
    font-size: 17px;
    margin: 7px 0;
  }

  .photo {
    max-width: 300px;
    height: 100%;
  }

  .file_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px 0;
    padding: 10px;
    background-color: $lightgray;
    border-radius: 5px;

    p {
      margin-left: 5px;
    }

    img {
      max-width: 20px;
    }
  }

  .date {
    color: $gray1;
  }

  .delete_button_wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    img {
      cursor: pointer;
      height: 20px;
    }
  }
}

.user_message {
  min-width: 300px;
  word-break: break-all;
  margin: 0 auto 0 0;
  .delete_button_wrapper {
    img {
      display: none;
    }
  }
}
