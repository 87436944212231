@import '../../../styles/index.scss';

.upload-file__wrapper {
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;

  .upload-file__btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 10px;

    .upload-file__btn__text {
      color: $purple;
    }

    .upload-file__input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .btn__label {
      padding: 10px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        margin-bottom: 15px;
      }
    }

    .error {
      margin-top: 15px;
      color: #f34245;
    }
  }
}

.drag-container {
  height: 100px;
  width: 100%;
  border: 1px dashed #4E44C6;
  border-radius: 10px;
  display: flex;
}