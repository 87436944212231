@import '../../../styles/index';

.table__id-column {
  text-align: center;
  font-weight: 800;
}

.table__status-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.type {
  background: $darknessBlue;
  padding: 5px 10px;
  border-radius: 6px;
  color: $white;
}

.archive {
  width: 20px;
  cursor: pointer;
}
