@import "../../../../../../../styles/index";

.preview__wrapper {
	display: flex;
	flex-direction: column;

	.uploaded-files {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));

		.uploaded-file__wrapper {
			position: relative;
			width: 200px;
			border-radius: 8px;
			height: 100px;
			margin-bottom: 25px;

			.uploaded-file__header {
				position: absolute;
				top: 12px;
				right: 12px;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				z-index: 2;
			}

			.uploaded-file {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
				border-radius: 8px;
			}

			.uploaded-file__app {
				width: 60px;
				height: auto;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			.uploaded-file__footer {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				background-color: $middleGray;
				padding: 10px;
				border-radius: 0 0 8px 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.footer__type {
					text-transform: uppercase;
					font-weight: 600;
					font-size: 12px;
					z-index: 2;
					width: 85%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}
