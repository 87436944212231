@import '../../../../../../styles/index';

.tabsWrapper {
  margin: 10px 0 25px;

  .item {
    padding: 5px 15px;
    margin-top: 5px;
    border-radius: 5px;
    position: relative;
    overflow: visible;

    .count {
      border-radius: 50%;
      padding: 2px 5px;
      background-color: $mint;
      color: $white;
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
}
