@import "../../../../../styles/index";

.entity_form {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	height: 100%;
	width: 100%;

	.address_section {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
		margin-bottom: 20px;
	}

	hr {
		margin: 20px 0;
	}

	.form_title {
		padding-left: 20px;
	}

	.main_form {
		overflow-y: auto;
		height: 100%;
		margin-top: 40px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;

		.select_wrapper {
			margin-bottom: 15px;
			.select_label {
				margin-bottom: 5px;
				padding-left: 10px;
				font-weight: 600;
				font-size: 12px;
			}
			.select_error {
				font-size: 12px;
				line-height: 1.2;
				padding-left: 10px;
				margin-bottom: 10px;
				margin-top: 5px;
				color: $red
			}
		}

		.modal__title {
			margin: 40px 0;
		}

		.modal__header {
			align-self: flex-end;
			cursor: pointer;
		}

		.submit-btn__wrapper {
			margin-top: 40px;
			display: flex;
			justify-content: flex-end;
		}

		.form-field__wrapper {
			margin-bottom: 25px;
		}
	}
}

.entity_mbl {
	.address_section {
		display: flex;
		flex-direction: column;
	}

	.main_form {
		overflow-y: visible;
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
	}
}
