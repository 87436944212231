@import '../../../../styles/index.scss';

.primary__btn {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &>span {
    font-size: inherit;
    font-weight: $font-weight-bolder;
    color: inherit;
  }

  .left-icon {
    margin-right: 5px;
  }

  .right-icon {
    margin-left: 5px;
  }

  &:disabled {
    opacity: 0.5;
  }
}
