@import '../../../styles/index.scss';

.field__wrapper {
  margin-bottom: 20px;

  .field__value {
    width: 100%;
    font-size: 16px;
    border: none;
    padding: 10px;
    color: $black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
  }

  .field__value__text {
    min-height: 80px;
    height: fit-content;
    align-items: start;
    padding-top: 8px;
  }

  .field__label {
    display: block;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 13px;
    font-weight: $font-weight-bold;
  }
}
