@import "../../../styles/index";

.chat {
  position: relative;
  background-color: $white;
  min-height: 400px;
  max-height: 600px;
  border-radius: 14px 14px 0 0;

  .header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 42px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: $lightViola;
    font-weight: $font-weight-bolder;
    color: $viola;
    border-radius: 14px 14px 0 0;
  }

  .content {
    padding: 50px 30px 110px;
    max-height: inherit;
    min-height: 500px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
    .empty_space {
      width: 100%;
      height: 20px;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px 20px;
    background-color: $lightViola;
  }
}
