@import 'styles/index';

.container-inner {
  display: flex;
  justify-content: space-between;

  .info {
    background: $white;
    border-radius: 10px;
    padding: 25px 50px;
    width: 70%;

    .info__title {
      font-size: 22px;
      font-weight: $font-weight-normal;
      line-height: 22px;
      letter-spacing: 0.3499999940395355px;
      text-align: left;
    }

    .block-wrapper {
      margin-bottom: 50px;

      .info__body {
        display: grid;
        grid-template-columns: repeat(2, 250px);
        grid-gap: 50px;
        margin-top: 30px;

        .detail {
          .detail__title {
            font-size: 13px;
            font-weight: $font-weight-bold;
            line-height: 16px;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 5px;
          }

          .detail__body {
            border: 1px solid $gray2;
            padding: 10px;
            border-radius: 40px;
            min-height: 40px;
          }
        }
      }
    }

    .products {
      margin-top: 20px;

      .product {
        border-radius: 17px;
        border: 1px solid $gray2;
        margin: 5px 0;

        .product__header {
          border-radius: 17px 17px 0 0;
          padding: 5px 20px;
          background-color: $gray3;
          border-bottom: 1px solid $gray2;
        }

        .product__body {
          display: flex;
          align-items: center;
          padding: 20px 20px;

          img {
            width: 60px;
            height: 60px;
          }

          .product__body__description {
            max-width: 400px;
          }

          .product__body__qty {
            margin-left: auto;
            border: 1px solid $gray2;
            border-radius: 4px;
            padding: 0 5px;
          }
        }
      }
    }
  }

  .made {
    background: $white;
    border-radius: 10px;
    padding: 25px 50px;
    width: 100%;
    height: fit-content;
    min-height: 350px;

    &__box {
      display: flex;
      gap: 30px;
      align-items: center;
      flex-direction: column;
      width: 28%;
    }

    .code__box {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow: scroll;
      background-color: rgb(235, 235, 235);
      width: 100%;
      min-height: 200px;
      height: fit-content;
      border-radius: 10px;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: #4e4e4e;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background: #373737;
      }
    }

    .made__actions {
      .actions__item {
        margin-bottom: 20px;

        .made__title {
          font-size: 15px;
          margin-bottom: 10px;
          font-weight: $font-weight-normal;
          line-height: 22px;
          letter-spacing: 0.3499999940395355px;
          text-align: left;
        }
      }
    }
  }
}


//temporary json highlight

.pre {
  padding: 5px;
  margin: 15px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}