@import '../../../../styles/index.scss';

.container__inner {
  display: flex;
  flex-direction: column;
  width: 100%;

  .cards_wrapper {
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    .cards_item {
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 23px;
      .item_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        p {
          font-weight: 400;
          font-size: 14px;
        }
        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
      .item_option {
        width: 100%;
        background-color: #F9F9F9;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .option_values {
          display: flex;
          align-items: center;
          .value_color {
            border-radius: 25px;
            width: 11px;
            height: 11px;
            margin-right: 10px;
          }
          .value_description {
            font-size: 15px;
            color: #B3A7A7;
          }
        }
        img {
          cursor: pointer;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
