@import "styles/index";

.entity_form {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: scroll;
	width: 100%;

	&::-webkit-scrollbar {
		display: none;
	}

	.form_title {
		padding-left: 20px;
		margin-bottom: 30px;
	}
	.select_wrapper {
		width: 100%;
		margin-bottom: 15px;
		.select_label {
			margin-bottom: 5px;
			padding-left: 10px;
			font-weight: 600;
			font-size: 12px;
		}
		.select_error {
			font-size: 12px;
			line-height: 1.2;
			padding-left: 10px;
			margin-bottom: 10px;
			margin-top: 5px;
			color: $red
		}
	}
	.search_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	.main_form {
		overflow-y: auto;
		height: 100%;
		margin-top: 20px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;

		.date {
			.date__title {
				display: block;
				margin-bottom: 5px;
				margin-left: 10px;
				font-size: 13px;
				font-weight: $font-weight-bold;
			}
		}

		.modal__title {
			margin: 40px 0;
		}

		.modal__header {
			align-self: flex-end;
			cursor: pointer;
		}

		.submit-btn__wrapper {
			margin-top: 40px;
			display: flex;
			justify-content: flex-end;
		}

		.form-field__wrapper {
			margin-bottom: 25px;
		}
	}
	.form__action {
		display: flex;
		justify-content: flex-end;
		margin-top: 40px;
	}
	.spaced__buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
	}
}

.entity_mbl {
	.address_section {
		display: flex;
		flex-direction: column;
	}

	.main_form {
		overflow-y: auto;
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
	}
}
