@import "../../../styles/index.scss";

.file__full-size {
	padding: 60px 20px;
	height: 100%;
	overflow: auto;
	width: 100%;
	background-color: rgba(46, 51, 56, 0.95);
	position: relative;

	.arrow__btn {
		position: absolute;
		top: 20px;
		left: 15px;
		height: 30px;
		width: auto;
		object-fit: contain;
		display: block;
		padding: 5px 10px;
		cursor: pointer;

		path {
			fill: $white;
		}
	}

	.file__wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.file__header {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			width: 100%;
			gap: 10px;
			margin-bottom: 10px;

			.info {
				padding: 5px;
				background-color: $purple;
				color: $white;
				border-radius: 7px;
			}
		}

		& > .uploaded-file {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.file_mbl {
	position: absolute;
	z-index: 200;
}
