@import "../../../styles/index";

.activities {
  position: relative;
  background-color: $white;
  min-height: 400px;
  max-height: 600px;
  border-radius: 14px 14px 0 0;
  margin: 35px 0;

  .header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 42px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: $lightViola;
    font-weight: $font-weight-bolder;
    color: $viola;
    border-radius: 14px 14px 0 0;
  }

  .content {
    padding: 50px 45px 10px 10px;
    max-height: inherit;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      color: $viola;
      font-weight: $font-weight-bolder;
      font-size: 20px;
      margin: 20px 0;
    }
  }
}
