@import 'styles/index';

.table__id-column {
  text-align: center;
  font-weight: 800;
}

.table__status-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brt_labels {
  position: relative;

  &_information {
    display: none;
  }

  &:has(.brt_labels_title:hover) {
    .brt_labels_information {
      display: flex;
      position: absolute;
      color: black;
      background-color: white;
      top: -30px;
      left: 50%;
      border-radius: 10px;
      padding: 5px 10px;
      transform: translate(-50%, 0);
    }
  }


  &_title {
    cursor: pointer;
    color: white;
  }
}

.type {
  background: $darknessBlue;
  padding: 5px 10px;
  border-radius: 6px;
  color: $white;
}

.escaped {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 auto;
}

.escaped_active {
  background-color: $green;
}

.escaped_inactive {
  background-color: $red;
}

.positive_percentage {
  color: $green;
}

.negative_percentage {
  color: $red;
}

.tableColumn {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.glsContainer {
  display: flex;
  align-items: center;
  border: 1px solid $gray1;
  border-radius: 6px;
  padding: 0 5px;
  gap: 10px;
  width: 125px;

  .subcolumn {
    width: 50%;
    word-break: keep-all;
  }


  .positive {
    color: $green;
  }

  .negative {
    color: $red;
  }

  .divider {
    background-color: $gray1;
    width: 1px;
    height: 40px;
  }

  &__disable {
    border: 1px solid $gray2;

    .divider {
      background-color: $gray2;
    }
  }

}

.brtContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}