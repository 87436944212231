$middleDark: #191A1B;
$viola: #8944AB;
$purple: #4E44C6;
$violaOpacity2: #EEEEFC;
$darkBlue: #0040DD;
$blue: #6B9DFF;
$middleGray: #DCDFE3;
$textGray: #AEAEB2;
$gray: rgba(46, 51, 56, 0.9);
$lightgray: #F1F2F3;
$gray2: #EAEAEA;
$gray3: #FAFAFA;
$gray1: #AAAAAA9C;
$gray4: #87868E;
$gray5: #F6F7F8;
$lightGreen: #D1FBB8;
$green: #8DD27B;
$red: #D70015;
$crimson: #ba5b84;
$lightBlue: #D9EBFF;
$turquoise: #6AE7D7;
$blueHover: #007AFF;
$blue: #409CFF;
$white: #FFFFFF;
$clearGray: #F6F7F8;
$yellow: #F5E23D;
$darkGray: #22262A;

$turquoise: #58D7C7;
$darknessBlue: #09183F;
$black: #2E3338;
$darkBlack: #090A0B;
$viola2: #9741A8;
$lightViola: #E6E7FC;
$lightBlue: #6AB9D2;
$mint: #58D7C7;
