.modal__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h3 {
    text-align: center;
    line-height: 1.2;
  }

  .modal__actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    &>button:first-child {
      margin-bottom: 15px;
    }
  }
}
