@import '../../../styles/index.scss';

.card {
        background: white;
        max-width: 50%;
        border-radius: 10px;

        hr {
                border: 0.5px solid $gray3;
        }

        .header {

                padding: 10px;
        }

        .body {
                padding: 10px;
        }
}