@import "../../../../../styles/index";

.entity_form {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	width: 100%;

	.form_title {
		padding-left: 20px;
	}

	.main_form {
		overflow-y: auto;
		height: 100%;
		margin-top: 40px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
	}
}

.entity_mbl {
	.main_form {
		overflow-y: visible;
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
	}
}
