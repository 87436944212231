@import "../../../../../../styles/index.scss";

.entity_form {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	.form_title {
		padding-left: 20px;
	}

	.main_form {
		flex-shrink: 0;
		height: 100%;
		padding: 40px 20px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px;
		row-gap: 10px;

		.input-label {
			display: flex;
			gap: 5px;
			margin-bottom: 5px;
			padding-left: 10px;
			font-weight: 600;
			font-size: 12px;

			p {
				color: $crimson;
				font-weight: $font-weight-bold;
			}
		}

		.select_wrapper {
			margin-bottom: 10px;
			.select_label {
				margin-bottom: 5px;
				padding-left: 10px;
				font-weight: 600;
				font-size: 12px;
			}
			.select_error {
				font-size: 12px;
				line-height: 1.2;
				padding-left: 10px;
				margin-bottom: 10px;
				margin-top: 5px;
				color: $red
			}
		}

		.modal__title {
			margin: 40px 0;
		}
	}
	.submit-btn__wrapper {
		margin-top: 40px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
