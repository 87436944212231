@import '../../../../../../../styles/index';

.upload-file__btn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 10px;

  .upload-file__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .btn__label {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      height: 30px;
      margin-bottom: 15px;
    }
  }

  .error {
    margin-top: 15px;
    color: #f34245;
  }
}
