@import '../../../../styles/index';

.return_detail_container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .return_statuses_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    margin-right: 10px;
    flex-wrap: wrap;

    .status_item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .item_label {
        font-size: 20px;
        font-weight: 500;
        margin-left: 20px;
      }

      .item_circle {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 46px;
        height: 46px;
        border-radius: 46px;
        background-color: #a4a0a0;
        cursor: pointer;

        .item_value {
          color: white;
          font-size: 24px;
        }

        img {
          display: none;
        }
      }

      .active {
        background-color: $green;
        cursor: default;
      }

      .completed {
        background-color: $blue;

        .item_value {
          display: none;
        }

        img {
          display: block;
        }
      }
    }
  }

  .return_data_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .main_content_wrapper {
      width: 60%;
      height: fit-content;
      background-color: white;
      padding: 30px;
      border-radius: 20px;

      .entity_form {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .form_action {
          display: flex;
          justify-content: space-between;

          .form_title {
            margin-bottom: 30px;
          }
        }

        .main_form {
          overflow-y: auto;
          height: 100%;
          margin-top: 20px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 70px;
          row-gap: 10px;
        }
      }

      .status_block {
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        .status_block_title {
          margin-bottom: 30px;
        }
      }

      .products_container {
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        .edit-product {
          position: absolute;
          top: 0;
          right: 0;
          margin: 7px;
          cursor: pointer;
        }

        .products_title {
          margin-bottom: 30px;
        }

        .products-list {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .product-item {
            border: 1px solid $gray2;
            background-color: $white;
            padding: 15px;
            border-radius: 17px;

            .first-row {
              display: flex;
              justify-content: space-between;

              .checkbox {
                width: 5%;
              }

              .image {
                width: 150px;
                height: auto;
              }

              .info {
                width: 55%;

                .sku {
                  margin: 10px 0;
                }

                .name-info {
                  display: flex;
                  gap: 5px;

                  .name-status {
                    display: flex;
                    width: 50%;
                    flex-direction: column;
                    gap: 10px;

                    .name {
                      font-size: 16px;
                    }

                    .status {
                      padding: 5px 15px;
                      border-radius: 5px;
                      height: fit-content;
                      width: fit-content;
                      color: $white;
                    }
                  }

                  .info-files {
                    width: 50%;

                    .files {
                      margin-top: 10px;
                      display: flex;
                      gap: 10px;

                      img {
                        cursor: pointer;
                      }

                      .files__qty {
                        font-size: 11px;
                        border-radius: 5px;
                        padding: 5px 15px;
                        background-color: $blue;
                        color: $white;
                      }
                    }
                  }
                }

                .quantities {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(50px, 100px));
                  grid-gap: 10px;
                  margin-top: 25px;

                  .qty-item {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .qty-title {
                      font-size: 11px;
                      color: $gray4;
                    }

                    .qty-value {
                      padding: 5px 15px;
                      border: 1px solid $gray4;
                      border-radius: 10px;
                    }
                  }
                }
              }

              .edit {
                max-width: 5%;

                img {
                  cursor: pointer;
                }
              }
            }

            .second-row {
              margin-top: 15px;
              display: flex;
              justify-content: flex-end;

              .long-value {
                margin-top: 5px;
                color: $gray4;
                overflow: hidden;
                width: 200px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }

  .properties-section {
    width: 38%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .return_options_wrapper {
      background-color: white;
      padding: 30px;
      border-radius: 20px;
      height: fit-content;

      .option_block {
        .option_action {
          display: flex;
          justify-content: space-between;
          margin-bottom: 40px;

          .option_title {
            margin-bottom: 10px;
          }
        }
      }

      .archive_block {
        .archive_title {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.return_detail_container_mbl {
  .return_statuses_wrapper {
    margin-bottom: 10px;
    margin-top: 10px;

    .status_item {
      margin-bottom: 10px;

      .item_label {
        font-size: 12px;
      }

      .item_circle {
        min-width: 30px;
        height: 30px;
        border-radius: 30px;

        .item_value {
          font-size: 18px;
        }
      }

      .completed {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .return_data_wrapper {
    flex-direction: column;
    margin-bottom: 15px;

    .main_content_wrapper {
      width: 100%;
      margin-bottom: 10px;
      padding: 15px 10px 10px;

      .entity_form {
        .form_title {
          margin-bottom: 15px;
        }

        .main_form {
          grid-template-columns: repeat(1, 1fr);
          row-gap: 0;
        }
      }

      .status_block {
        margin-top: 20px;

        .status_block_title {
          margin-bottom: 10px;
        }
      }

      .products_container {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .products_title {
          margin-bottom: 15px;
        }

        .product-item {
          .first-row {
            .info {
              width: 75%;

              .name-info {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  .properties-section {
    width: 100%;

    .return_options_wrapper {
      width: 100%;
      padding: 10px;

      .option_block {
        .option_action {
          margin-bottom: 30px;

          .option_title {
            margin-bottom: 20px;
          }
        }

        .option_form {
          .option_form_title {
            margin-top: 15px;
            margin-bottom: 15px;
          }
        }
      }

      .archive_block {
        margin-bottom: 15px;
        margin-top: 15px;

        .archive_title {
          margin-bottom: 10px;
        }
      }
    }
  }
}