@import 'styles/index';

.tableHead {
  background: #00189a;
  padding: 16px;

  .containerInner {
    .title {
      color: $white;
      text-transform: uppercase;
      font-weight: $font-weight-normal;
    }

    .subtitles {
      display: flex;
      justify-content: space-around;
      list-style: none;

      .subtitle {
        color: $white;
        text-transform: uppercase;
      }
    }
  }
}

.tableHeadWithSubtitles {
  .containerInner {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
