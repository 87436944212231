@import '../../../../../styles/index';

.container-inner {
  .info_wrapper {
    display: flex;
    gap: 20px;

    .info {
      width: 100%;
      background: $white;
      border-radius: 10px;
      padding: 25px 50px;
      margin-bottom: 60px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .info__title {
          font-size: 22px;
          font-weight: $font-weight-normal;
          line-height: 22px;
          letter-spacing: 0.3499999940395355px;
          text-align: left;
        }

        .type {
          padding: 5px 10px;
          background-color: $darknessBlue;
          color: $white;
          border-radius: 6px;
          min-width: 100px;
          text-align: center;
        }
      }

      .block-wrapper {
        margin-bottom: 50px;

        .info__body {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(25%, 40%));
          grid-gap: 50px;
          margin-top: 30px;

          .detail {
            .detail__title {
              font-size: 13px;
              font-weight: $font-weight-bold;
              line-height: 16px;
              letter-spacing: -0.5px;
              text-align: left;
              margin-bottom: 5px;
            }

            .detail__body {
              border: 1px solid $gray2;
              padding: 10px;
              border-radius: 40px;
              min-height: 40px;
            }
          }
        }
      }

      .products {
        margin-top: 20px;

        .product {
          border-radius: 17px;
          border: 1px solid $gray2;
          margin: 5px 0;

          .product__header {
            border-radius: 17px 17px 0 0;
            padding: 5px 20px;
            background-color: $gray3;
            border-bottom: 1px solid $gray2;
          }

          .product__body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;

            .product__body__description {
              max-width: 400px;
            }

            .product__body__qty {
              border: 1px solid $gray2;
              border-radius: 4px;
              padding: 0 5px;
            }
          }
        }
      }
    }

    .addresses {
      width: 100%;
      background: $white;
      border-radius: 10px;
      padding: 25px 50px;
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .orders {
    .orders__title {
      font-size: 24px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.3499999940395355px;
      text-align: left;
    }

    .orders__filter {
      display: flex;
      gap: 15px;
      margin: 10px 0 30px;

      .orders__filter__item {
        position: relative;
        width: 100px;
        padding: 5px 0;
        text-align: center;
        border-radius: 6px;
        background-color: $gray1;
        cursor: pointer;

        .orders__filter__item__count {
          position: absolute;
          top: -5px;
          right: -3px;
          padding: 2px;
          border-radius: 1.5px;
          color: $white;
          font-size: 10px;
          background-color: $turquoise;
        }
      }

      .orders__filter__item_active {
        background-color: $darknessBlue;
        color: $white;
      }
    }
  }
}

.container_mbl {
  .info_wrapper {
    flex-direction: column;

    .info {
      margin-bottom: 0;

      .block-wrapper {
        .info__body {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
}