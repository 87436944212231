@import '../../../styles/index';

.table__id-column {
  text-align: center;
  font-weight: 800;
}

.status {
  color: $white;
  border-radius: 5px;
  padding: 5px 10px;
}

.table__status-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
