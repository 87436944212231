@import "./modules/colors.scss";
@import "./modules/mixins.scss";
@import "./modules/variables.scss";
@import "./modules/reset.scss";

* {
  font-family: $font-family;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: $base-font-size;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  background-color: $clearGray;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-shrink: 1;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: $font-weight-bold;
  color: $darkBlack;
  letter-spacing: 0.35px;
}

h1,
.h1 {
  font-size: 24px;
  line-height: 1.5;
}

h2,
.h2 {
  font-size: 22px;
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {
  font-size: 16px;
}

h5,
.h5 {
  font-size: 14px;
}

b,
strong {
  font-weight: $font-weight-bolder;
}

button {
  outline: none;
  cursor: pointer;
}

span,
p,
b,
strong,
div {
  color: $black;
}

.eye-icon {
  width: 22px;
  height: 18px;
}

*[class^='badge-'] {
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  color: $darkBlue;
  font-weight: $font-weight-bolder;
  width: 120px;
  margin: 0 auto;
}

.MuiTooltip-popper {
  * {
    background-color: $clearGray !important;
    color: $black !important;
    font-size: 11px !important;
    letter-spacing: 0.4px;
  }
}

.checked__btn {
  position: relative;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  cursor: pointer;

  &::after {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    content: '\2212';
    border: 2px solid $red;
    background-color: #FBE5E8;
    color: $red;
  }
}

.react-datepicker {
  width: 100%;
  min-width: 375px;
  height: 100%;
  background-color: $darkGray !important;
  border: none !important;

  @media (max-width: 480px) {
    width: 100%;
    min-width: initial;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation--previous {
    left: calc(100% - 68px);
  }
  .react-datepicker__navigation-icon::before {
    border-color: $yellow;
  }
  .react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
    border-color: $yellow;
  }

  .react-datepicker__month-container {
    width: 100%;
    background-color: $darkGray;
    padding: 0 20px;

    .react-datepicker__header {
      background-color: $darkGray !important;
      border: none;
      margin-bottom: 10px;
      text-align: left;

      .react-datepicker__current-month {
        color: #cccc;
        font-weight: $font-weight-bold;
        font-size: 17px;
        margin-bottom: 15px;
        text-transform: capitalize;
      }

      .react-datepicker__day-names {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .react-datepicker__day-name {
          width: 36px;
          line-height: 36px;
          color: #cccc;
          font-weight: $font-weight-bold;
          font-size: 15px;
          text-transform: uppercase;
        }
      }
    }

    .react-datepicker__week {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .react-datepicker__day {
        width: 36px;
        line-height: 36px;
        color: #cccc;
        font-weight: $font-weight-bold;
        font-size: 20px;
      }
    }

    .react-datepicker__day--highlighted {
      color: $black !important;
    }

    .react-datepicker__week:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day:hover {
    background-color: $yellow;
    border-radius: 50%;
    color: $black !important;
    font-weight: $font-weight-bold;
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: $yellow !important;
  }

  .react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
  .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
  .react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
  .react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
    background-color: rgba(225, 228, 28, 0.2);
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: rgba(225, 228, 28, 0.8);
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker__day--disabled:hover {
    background-color: rgba(225, 228, 28, 0.2);
  }
}

.popper__hidden {
  display: none;
}

.react-datepicker-popper {
  z-index: 3000 !important;
}
