@import '../../../../styles/index.scss';

.container__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .login__container {
    color: #06262D;
    width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      margin-bottom: 50px;
      text-transform: uppercase;
      font-weight: $font-weight-bolder;
      color: $gray;
    }

    .logo {
      width: 50%;
      display: block;
      margin-bottom: 50px;
    }

    h1 {
      color: $black;
      margin-bottom: 15px;
    }

    .login__form {
      display: flex;
      flex-direction: column;
      width: 100%;

      &>*:last-child {
        margin-top: 25px;
      }
    }

    .forgot-pwd__link {
      color: $blue;
      text-decoration: underline;
      font-size: 16px;
      text-align: right;
      cursor: pointer;
    }

    .errorMsg {
      display: flex;
      color: $red;
      font-size: 12px;
      line-height: 1.2;
    }
  }

}
