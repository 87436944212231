@import 'styles/index';

textarea {
  resize: none;
  padding: 10px;
  margin-top: 15px;
}

.container-inner {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .info {
    background: $white;
    border-radius: 10px;
    padding: 25px 50px;
    width: 60%;

    .info__title {
      font-size: 22px;
      font-weight: $font-weight-normal;
      line-height: 22px;
      letter-spacing: 0.3499999940395355px;
      text-align: left;
    }

    .block-wrapper {
      margin-bottom: 50px;

      .info__body {
        display: grid;
        grid-template-columns: repeat(2, 250px);
        grid-gap: 50px;
        margin-top: 30px;

        .detail {
          .detail__title {
            font-size: 13px;
            font-weight: $font-weight-bold;
            line-height: 16px;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 5px;
          }

          .detail__body {
            border: 1px solid $gray2;
            padding: 10px;
            border-radius: 40px;
            min-height: 40px;
          }
        }
      }
    }

    .products_container {
      display: flex;
      flex-direction: column;
      margin-top: 60px;
      .edit-product {
        position: absolute;
        top: 0;
        right: 0;
        margin: 7px;
        cursor: pointer;
      }
      .products_title {
        margin-bottom: 30px;
      }
      .products-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .product-item {
          border: 1px solid $gray2;
          background-color: $white;
          padding: 15px;
          border-radius: 17px;

          .first-row {
            display: flex;
            justify-content: space-between;

            .image {
              width: 100px;
              height: 100px;
            }
            
            .image img{
              width: 100%;
              height: 100%;
            }

            .inner_info {
              width: 80%;

              .sku {
                margin: 10px 0;
              }

              .name-info {
                display: flex;
                gap: 5px;

                .name-status {
                  display: flex;
                  width: 50%;
                  flex-direction: column;
                  gap: 10px;

                  .name {
                    font-size: 16px;
                  }
                  .status {
                    background-color: $blue;
                    padding: 5px 15px;
                    border-radius: 5px;
                    height: fit-content;
                    width: fit-content;
                    color: $white;
                  }
                }

                .info-files {
                  width: 50%;

                  .files {
                    margin-top: 10px;
                    display: flex;
                    gap: 10px;

                    img {
                      cursor: pointer;
                    }

                    .files__qty {
                      font-size: 11px;
                      border-radius: 5px;
                      padding: 5px 15px;
                      background-color: $blue;
                      color: $white;
                    }
                  }
                }
              }

              .quantities {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(50px, 100px));
                grid-gap: 10px;
                margin-top: 25px;

                .qty-item {
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  .qty-title {
                    font-size: 11px;
                    color: $gray4;
                  }
                  .qty-value {
                    padding: 5px 15px;
                    border: 1px solid $gray4;
                    border-radius: 10px;
                  }
                }
              }
            }
          }

          .second-row {
            margin-top: 15px;
            display: flex;
            justify-content: flex-end;
            .long-value {
              margin-top: 5px;
              color: $gray4;
              overflow: hidden;
              width: 200px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }

    .products {
      margin-top: 20px;

      .product {
        border-radius: 17px;
        border: 1px solid $gray2;
        margin: 5px 0;

        .product__header {
          border-radius: 17px 17px 0 0;
          padding: 5px 20px;
          background-color: $gray3;
          border-bottom: 1px solid $gray2;
        }

        .product__body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 20px;

          .product__body__description {
            max-width: 400px;
          }

          .product__body__qty {
            border: 1px solid $gray2;
            border-radius: 4px;
            padding: 0 5px;
          }
        }
      }
    }
  }

  .made {
    background: $white;
    border-radius: 10px;
    padding: 25px 50px;
    width: 38%;
    height: fit-content;

    .made__actions {
      margin: 10px 0 25px;
      display: flex;
      gap: 10px;

      .actions__item {
        .made__title {
          font-size: 15px;
          margin-bottom: 10px;
          font-weight: $font-weight-normal;
          line-height: 22px;
          letter-spacing: 0.3499999940395355px;
          text-align: left;
        }
      }
    }
  }
}

.container_mbl {
  flex-direction: column;

  .info {
    width: 100%;
    padding: 10px 20px;

    .block-wrapper {
      .info__body {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
    }

    .products_container {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      .products_title {
        margin-bottom: 15px;
      }
      .product-item {
        .first-row {
          .inner_info {
            width: 85%;

            .name-info {
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  .made {
    width: 100%;
  }
}