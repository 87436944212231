@import '../../styles/index.scss';

.sidebar__wrapper {
  height: 100vh;
  width: 280px;
  min-width: 280px;
  transition: 200ms ease-in;

  .sidebar__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    width: 100%;
    height: 100%;

    .logo__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 20px 0;
      width: 100%;
      .logo {
        max-width: 150px;
        object-fit: contain;
      }
      .collapse_arrow {
        cursor: pointer;
        transform: rotate(180deg);
        border: 1px solid rgba(36, 34, 32, 0.07);
        width: 25px;
        height: 25px;
        padding: 5px;
        border-radius: 100%;
        background-color: rgba(36, 34, 32, 0.07);
      }
      .collapse_arrow__rotate {
        transform: rotate(0deg);
      }
    }

    .nav-bar {
      height: fit-content;
      margin: 0 15px;
      margin-top: 70px;
      ul {
        li {
          list-style: none;
        }
      }
    }
    .exit_button_wrapper {
      display: flex;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
}


.mobile_sidebar__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  .sidebar__inner {
    width: 100%;
  }
}

.collapse_sidebar__wrapper {
  position: relative;
  width: 80px;
  min-width: 80px;
  .sidebar__inner {
    .logo__wrapper {
      .logo {
        display: none;
      }
      .collapse_arrow {
        transform: rotate(0deg);
      }
    }
  }
}

