@import '../../../../../styles/index';

.card {
  border-bottom: 1px solid $gray1;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  align-items: center;
  padding: 0 0 20px 10px;
  border-left: 3px solid $viola;

  .title {
    font-weight: $font-weight-bolder;
    font-size: 11px;
    color: $gray1;

    .id {
      font-size: 11px;
      color: $viola;
    }
  }

  .description {
    font-size: 17px;
    padding-top: 5px;
  }

  .time {
    border-radius: 7px;
    padding: 10px;
    background-color: $gray3;
    color: $gray2;
    width: fit-content;
  }
}
